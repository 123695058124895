export default [
  {
    title: 'Items',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Items',
        route: 'items.list',
        icon: 'ClipboardIcon',
        resource: 'Product',
        action: 'index',
      },
      {
        route: 'items.store',
      },
      {
        route: 'items.edit',
      },
      {
        title: 'Categories',
        route: 'categories.list',
        icon: 'LayersIcon',
        resource: 'Category',
        action: 'index',
      },
      {
        title: 'Filters',
        route: 'filters.list',
        icon: 'SearchIcon',
        resource: 'Type',
        action: 'index',
      },
    ],
  },
]
