export default [
  {
    title: 'Staff',
    icon: 'UserIcon',
    children: [
      {
        title: 'Staff',
        route: 'staff-list',
        icon: 'UserIcon',
        resource: 'Staff',
        action: 'index',
      },
      {
        title: 'Roles',
        route: 'roles-list',
        icon: 'UserCheckIcon',
        resource: 'Role',
        action: 'index',
      },
    ],
  },
]
